import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Section } from 'app/components/Common/Section'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  bottomText?: string
  description?: string
  languageCode: string
  numbers?: string[]
  numbersTitle?: string
}

export const Info = memo(function Info({
  bottomText,
  description,
  languageCode,
  numbers,
  numbersTitle,
}: Props) {
  return (
    <Container>
      <Section label="info" languageCode={languageCode} />
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {numbersTitle ? <NumbersTitle>{numbersTitle}</NumbersTitle> : null}
      {numbers
        ? numbers.map((item, index) => (
            <Number
              key={index}
              href={`tel:${
                // @ts-ignore
                item.label
              }`}
            >
              {
                // @ts-ignore
                item.label
              }
            </Number>
          ))
        : null}
      {bottomText ? (
        <BottomText dangerouslySetInnerHTML={{ __html: bottomText }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 51.875rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2rem;
  margin: 19.6875rem auto 12.5rem;
  padding: 4.25rem 7.361vw 5.625rem;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 12.5rem auto 7.5rem;
    padding: 3rem 1.875rem 3.75rem;
  }

  @media (max-width: 767px) {
    margin-top: 10rem;
  }
`

const Description = styled.div`
  font-weight: 300;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }
`

const NumbersTitle = styled.div`
  font-weight: 700;
  margin-top: 2.5rem;
`

const Number = styled.a`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.colors.variants.primaryDark1};
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  margin: 1.25rem 0.75rem 0;
  padding: 0.8125rem 1.25rem 0.9375rem;
  transition: 0.3s ease-in-out;
  &:hover {
    color: ${rgba(theme.colors.variants.primaryDark1, 0.5)};
  }
`

const BottomText = styled.div`
  font-weight: 300;
  margin-top: 2.5rem;

  a {
    font-weight: 700;
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }
`
